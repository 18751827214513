export const twitter = `<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1031_10343)">
<path d="M7.57194 3.57355L7.57355 3.57194C8.79714 2.34556 10.3731 1.68775 12.6892 1.34759C15.0392 1.00245 18.0222 1 21.9967 1C25.9712 1 28.9545 1.00243 31.3055 1.34683C33.6222 1.68621 35.2004 2.34254 36.4273 3.56622C37.6543 4.79331 38.3122 6.37149 38.6524 8.68833C38.9976 11.0391 39 14.0222 39 17.9967C39 21.9712 38.9976 24.9543 38.6524 27.3051C38.3122 29.622 37.6543 31.2002 36.4273 32.4273C35.2003 33.6542 33.6207 34.3122 31.3027 34.6524C28.9511 34.9976 25.9679 35 21.9967 35C18.0255 35 15.0424 34.9976 12.6916 34.6524C10.3745 34.3122 8.79699 33.6543 7.57355 32.4281L7.57275 32.4273C6.34581 31.2003 5.68782 29.6207 5.3476 27.3027C5.00244 24.9511 5 21.9679 5 17.9967C5 14.0255 5.00244 11.0424 5.3476 8.69159C5.68781 6.37446 6.34571 4.79699 7.57194 3.57355Z" stroke="white" stroke-width="2"/>
<path d="M29.1498 13.7383C29.1612 13.9023 29.1612 14.0664 29.1612 14.2305C29.1612 19.2344 25.4493 25 18.665 25C16.5749 25 14.6333 24.3789 13 23.3008C13.297 23.3359 13.5825 23.3477 13.8909 23.3477C15.6155 23.3477 17.2031 22.75 18.4708 21.7305C16.849 21.6953 15.4898 20.6055 15.0216 19.1055C15.25 19.1406 15.4784 19.164 15.7183 19.164C16.0495 19.164 16.3807 19.1172 16.6891 19.0352C14.9987 18.6836 13.7309 17.1601 13.7309 15.3203V15.2734C14.222 15.5547 14.7932 15.7305 15.3984 15.7539C14.4048 15.0742 13.7538 13.914 13.7538 12.6015C13.7538 11.8984 13.9365 11.2539 14.2563 10.6914C16.0723 12.9883 18.802 14.4882 21.8629 14.6523C21.8058 14.3711 21.7716 14.0781 21.7716 13.7852C21.7716 11.6992 23.4162 10 25.4607 10C26.5228 10 27.4822 10.457 28.1561 11.1953C28.9898 11.0313 29.7893 10.7148 30.4975 10.2813C30.2233 11.1602 29.6409 11.8985 28.8756 12.3672C29.618 12.2852 30.3376 12.0742 31 11.7813C30.4975 12.5312 29.8693 13.1992 29.1498 13.7383Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_1031_10343" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1031_10343"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1031_10343" result="shape"/>
</filter>
</defs>
</svg>
`
