export const sign = `<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2179_4403)">
<path d="M27.8888 55.7305C39.9013 55.7305 46.5931 55.7305 50.9346 51.286C55.2761 46.8415 55.3088 40.0753 55.3088 27.8696C55.3088 15.6639 55.3088 8.86453 50.9346 4.45326C46.5931 0.00878906 39.9013 0.00878906 27.8888 0.00878906C15.8762 0.00878906 9.18437 0.00878906 4.84289 4.45326C0.468748 8.86453 0.46875 15.6639 0.46875 27.8696C0.46875 40.0753 0.468748 46.8747 4.84289 51.286C9.18437 55.7305 15.8762 55.7305 27.8888 55.7305Z" fill="black"/>
<path d="M33.7192 38.639L30.6355 41.2266L26.6766 36.4927L21.2498 41.0869L16.7656 18.4344L33.7192 38.639Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.3409 34.5866C34.0347 33.1575 34.2536 30.6263 32.8281 28.9275C31.4028 27.2288 28.872 27.0048 27.1705 28.4247L17.9531 17.4398L39.3322 25.8634L37.5043 32.6672L33.5292 36.0027L32.3409 34.5866Z" fill="white"/>
<path d="M34.3408 36.9854L38.3359 33.6331L38.8947 34.2989L34.8995 37.6512L34.3408 36.9854Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_2179_4403">
<rect width="54.84" height="55.9825" fill="white" transform="translate(0.46875 0.00878906)"/>
</clipPath>
</defs>
</svg>
`
