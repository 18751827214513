export const fb = `<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1031_10344)">
<path d="M7.57194 3.57355L7.57355 3.57194C8.79714 2.34556 10.3731 1.68775 12.6892 1.34759C15.0392 1.00245 18.0222 1 21.9967 1C25.9712 1 28.9545 1.00243 31.3055 1.34683C33.6222 1.68621 35.2004 2.34254 36.4273 3.56622C37.6543 4.79331 38.3122 6.37149 38.6524 8.68833C38.9976 11.0391 39 14.0222 39 17.9967C39 21.9712 38.9976 24.9543 38.6524 27.3051C38.3122 29.622 37.6543 31.2002 36.4273 32.4273C35.2003 33.6542 33.6207 34.3122 31.3027 34.6524C28.9511 34.9976 25.9679 35 21.9967 35C18.0255 35 15.0424 34.9976 12.6916 34.6524C10.3745 34.3122 8.79699 33.6543 7.57355 32.4281L7.57275 32.4273C6.34581 31.2003 5.68782 29.6207 5.3476 27.3027C5.00244 24.9511 5 21.9679 5 17.9967C5 14.0255 5.00244 11.0424 5.3476 8.69159C5.68781 6.37446 6.34571 4.79699 7.57194 3.57355Z" stroke="white" stroke-width="2"/>
<path d="M19.9053 28V19.502H17V16.125H19.9053V13.4643C19.9053 10.5734 21.7083 9 24.3409 9C25.6023 9 26.6856 9.09277 27 9.13359V12.1543H25.1742C23.7424 12.1543 23.4659 12.8223 23.4659 13.7982V16.125H26.697L26.2538 19.502H23.4659V28" fill="white"/>
</g>
<defs>
<filter id="filter0_d_1031_10344" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1031_10344"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1031_10344" result="shape"/>
</filter>
</defs>
</svg>
`
