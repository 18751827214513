export const map = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_938_4542)">
<path d="M11.9973 20.7207L12.2902 20.9124L12.2903 20.9123L12.2905 20.9119L12.2914 20.9105L12.2951 20.9049L12.3094 20.883L12.364 20.7995L12.561 20.4976C12.7255 20.2453 12.9482 19.9032 13.1809 19.5438C13.6434 18.8297 14.1544 18.0342 14.3202 17.7501C15.6063 15.5564 17.0089 13.1504 17.7934 11.2605L17.794 11.259C18.2469 10.1538 18.3573 8.93802 18.1109 7.76931C17.8645 6.60068 17.2728 5.53301 16.4123 4.70472C15.2275 3.56086 13.6433 2.92413 11.9965 2.92982C10.3508 2.92509 8.76808 3.56182 7.58415 4.7049L7.58394 4.70511C6.4511 5.80068 5.74766 7.31718 5.74766 8.99947L5.74766 8.99968C5.74813 9.76894 5.89919 10.5307 6.19231 11.2419C6.94654 13.0756 8.30608 15.4088 9.55951 17.5599C9.59855 17.6269 9.63748 17.6938 9.67629 17.7604L9.67691 17.7614C9.84394 18.0459 10.3542 18.8384 10.8159 19.5499C11.0483 19.9079 11.2704 20.2485 11.4345 20.4997L11.631 20.8003L11.6854 20.8834L11.6997 20.9052L11.7034 20.9107L11.7043 20.9122L11.7045 20.9125L11.7046 20.9126L11.9973 20.7207ZM11.9973 20.7207L12.2902 20.9124L11.9976 21.3595L11.7046 20.9126L11.9973 20.7207ZM13.0655 17.0349L13.0656 17.0347C14.3463 14.8473 15.7097 12.5155 16.4542 10.7182L16.4543 10.7179C16.6813 10.1712 16.7977 9.58494 16.7967 8.993L16.7967 8.99244C16.7967 7.71453 16.2626 6.55593 15.3955 5.71823L13.0655 17.0349ZM13.0655 17.0349L13.0375 17.0827C12.6774 17.6986 12.3243 18.3024 11.9965 18.8753C11.6636 18.2932 11.3037 17.6773 10.9344 17.0484L10.8761 16.9486C9.60495 14.7721 8.25541 12.4613 7.53259 10.7058C7.19176 9.86324 7.11125 8.93788 7.30145 8.04912C7.49172 7.16003 7.94417 6.3484 8.60036 5.71903L8.60037 5.71904L8.60176 5.71769C9.46966 4.87599 10.6695 4.35401 11.9966 4.35401L11.9979 4.35401M13.0655 17.0349L11.9979 4.35401M11.9979 4.35401C13.2648 4.3492 14.4836 4.83858 15.3954 5.71816L11.9979 4.35401Z" fill="black" stroke="black" stroke-width="0.7"/>
<circle cx="11.9922" cy="8.9895" r="1.5" fill="#020202"/>
</g>
<defs>
<clipPath id="clip0_938_4542">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
`
