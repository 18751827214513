export const penLogo = `<svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M48.4902 7.62939e-06C27.2374 7.62939e-06 15.4228 0 7.70658 7.71635C-0.00976562 15.4326 -0.009758 27.2401 -0.009758 48.5C-0.009758 69.7599 -0.00976562 81.5674 7.70658 89.2837C15.4228 97 27.2374 97 48.4902 97C69.743 97 81.5576 97 89.2739 89.2837C96.9902 81.5674 96.9902 69.7528 96.9902 48.5C96.9902 27.2472 96.9902 15.4326 89.2739 7.71635C81.5576 0 69.743 7.62939e-06 48.4902 7.62939e-06Z" fill="black"/>
<mask id="mask0_1907_175" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="21" y="21" width="55" height="55">
<path d="M23.2705 35.6036L23.2705 47.9252L23.2705 60.2467C23.2705 67.0518 28.7871 72.5683 35.5921 72.5683L47.9137 72.5683L60.2353 72.5683C67.0403 72.5683 72.5568 67.0518 72.5568 60.2467L72.5568 47.9252L72.5568 35.6036C72.5568 28.7985 67.0403 23.282 60.2353 23.282L47.9137 23.282L35.5921 23.282C28.7871 23.282 23.2705 28.7985 23.2705 35.6036Z" fill="white"/>
<path d="M23.2705 35.6036L34.3283 35.6036C35.0263 35.6036 35.5921 35.0378 35.5921 34.3398L35.5921 23.282M23.2705 35.6036L23.2705 47.9252L23.2705 60.2467V60.2467C23.2705 67.0518 28.7871 72.5683 35.5921 72.5683V72.5683L47.9137 72.5683L60.2353 72.5683V72.5683C67.0403 72.5683 72.5568 67.0518 72.5568 60.2467V60.2467L72.5568 47.9252L72.5568 35.6036V35.6036C72.5568 28.7985 67.0403 23.282 60.2353 23.282V23.282L47.9137 23.282L35.5921 23.282M23.2705 35.6036V35.6036C23.2705 28.7985 28.7871 23.282 35.5921 23.282V23.282" stroke="white" stroke-width="3.28576"/>
</mask>
<g mask="url(#mask0_1907_175)">
<path d="M53.7765 26.5823C57.6496 26.8269 61.4724 28.3205 64.5959 31.1008C67.806 33.9582 69.7517 37.7067 70.3923 41.6418C75.4809 39.7374 77.4293 33.4737 74.2119 29.004C72.0062 25.94 69.1591 23.3935 65.869 21.5421C61.1008 18.8589 55.1734 21.443 53.7765 26.5823Z" fill="white"/>
<path d="M67.7285 43.0302C67.363 39.3761 65.6781 35.8437 62.7188 33.2096C59.8791 30.6819 56.3538 29.4156 52.829 29.3707L52.7626 29.4829C48.2692 37.0711 39.4845 41.0074 30.8305 39.3106L25.8232 72.875L47.8578 47.8526C46.3014 45.6395 46.4086 42.5694 48.2853 40.461C50.475 38.0011 54.2441 37.782 56.704 39.9717C59.1639 42.1613 59.383 45.9305 57.1934 48.3904C55.3375 50.4753 52.347 50.9505 49.9807 49.7137L28.0213 74.6507L60.3582 65.594C57.8353 57.3263 60.7259 48.362 67.6033 43.1255L67.7285 43.0302Z" fill="white"/>
</g>
</svg>

`
