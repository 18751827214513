import forEach from 'lodash/forEach'

// import { icons } from './icons'

import { icons } from '.'

import { NgModule } from '@angular/core'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'

@NgModule({
  imports: [MatIconModule],
})
export class SvgIconsModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    forEach(icons, (svgUrl, iconName) => {
      iconRegistry.addSvgIconLiteral(iconName, sanitizer.bypassSecurityTrustHtml(svgUrl))
    })
  }
}
