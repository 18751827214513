export const logoWhite = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_702_4972)">
<g clip-path="url(#clip1_702_4972)">
<g clip-path="url(#clip2_702_4972)">
<path d="M24.1782 48.3877C34.6921 48.3877 40.5409 48.3877 44.3617 44.5668C48.1826 40.746 48.1826 34.9059 48.1826 24.3833C48.1826 13.8608 48.1826 8.0207 44.3617 4.19985C40.5409 0.387695 34.7008 0.387695 24.1782 0.387695C13.6557 0.387695 7.81562 0.387695 4.00347 4.20855C0.182617 8.0207 0.182617 13.8695 0.182617 24.3833C0.182617 34.8972 0.182617 40.746 4.00347 44.5668C7.81562 48.3877 13.6644 48.3877 24.1782 48.3877Z" fill="white"/>
<path d="M33.1953 19.6399C31.3849 19.6399 30.0359 21.0412 30.0359 22.7645C30.0359 24.1744 31.0281 25.2711 32.2814 25.6018L29.7138 29.5445H32.116L34.3093 25.8716H35.9456V29.5445H37.9474V19.6399H33.1953ZM33.4738 24.2006C32.6469 24.2006 32.1334 23.5565 32.1334 22.8167C32.1334 22.0769 32.6034 21.3719 33.4738 21.3719H35.9456V24.2006H33.4738Z" fill="black"/>
<path d="M22.2113 19.6399L21.5498 21.4763L24.2827 21.4589L22.6638 27.3947C22.2026 29.1268 24.0042 30.4323 25.5447 29.414L29.0435 27.0379L28.0165 25.5235L24.5177 27.9431L26.8763 19.6399H22.2113Z" fill="black"/>
<path d="M27.3642 17.1158C27.3642 17.8033 27.0596 18.3691 25.9717 18.3691C24.9359 18.3691 24.5791 17.812 24.5791 17.1158C24.5791 16.4195 24.9359 15.8625 25.9717 15.8625C27.0596 15.8625 27.3642 16.4282 27.3642 17.1158Z" fill="black"/>
<path d="M18.8957 27.7167V17.342H11.1931V22.8775C11.1931 25.4799 10.4359 27.09 9.992 27.708H9.07812V32.2338H11.0277V29.5531H18.33V32.2425H20.2883V27.7167H18.8957ZM13.134 22.7643V19.1785H16.8939V27.708H12.046C12.4899 26.9943 13.134 25.1578 13.134 22.7643Z" fill="black"/>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_702_4972">
<rect width="48" height="48" fill="white"/>
</clipPath>
<clipPath id="clip1_702_4972">
<rect width="48" height="48" fill="white" transform="translate(0.182617 0.387695)"/>
</clipPath>
<clipPath id="clip2_702_4972">
<rect width="48" height="48" fill="white" transform="translate(0.182617 0.387695)"/>
</clipPath>
</defs>
</svg>
`
