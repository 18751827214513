export const instagram = `<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1031_10346)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.28146 4.27824L8.27824 4.28146C7.2586 5.29878 6.6592 6.6423 6.33699 8.83686C6.00489 11.0988 6 13.997 6 17.9967C6 21.9964 6.00489 24.8947 6.337 27.1575C6.65922 29.3528 7.25879 30.6991 8.27985 31.7201L8.28147 31.7218C9.29878 32.7414 10.6423 33.3408 12.8369 33.663C15.0988 33.9951 17.997 34 21.9967 34C25.9964 34 28.8947 33.9951 31.1575 33.663C33.3529 33.3408 34.6991 32.7412 35.7201 31.7201C36.7415 30.6988 37.3409 29.3539 37.663 27.1599C37.9951 24.898 38 21.9998 38 17.9967C38 13.9937 37.9951 11.0955 37.663 8.8336C37.341 6.64018 36.7418 5.29538 35.7209 4.27411C34.6999 3.25577 33.3547 2.6577 31.1605 2.33627C28.8982 2.00487 25.9996 2 21.9967 2C17.9937 2 15.0955 2.00489 12.8345 2.33698C10.6413 2.6591 9.29908 3.2583 8.28146 4.27824ZM37.1344 2.85911C40 5.72475 40 10.1048 40 17.9967C40 25.8887 40 30.2687 37.1344 33.1344C34.2687 36 29.8821 36 21.9967 36C14.1113 36 9.72475 36 6.86564 33.1344C4 30.2687 4 25.8821 4 17.9967C4 10.1113 4 5.72475 6.86564 2.86564C9.72475 0 14.1048 0 21.9967 0C29.8887 0 34.2687 0 37.1344 2.85911Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 24C25.3137 24 28 21.3137 28 18C28 14.6863 25.3137 12 22 12C18.6863 12 16 14.6863 16 18C16 21.3137 18.6863 24 22 24ZM22 26C26.4183 26 30 22.4183 30 18C30 13.5817 26.4183 10 22 10C17.5817 10 14 13.5817 14 18C14 22.4183 17.5817 26 22 26Z" fill="white"/>
<circle cx="30.5" cy="8.5" r="2.5" fill="white"/>
</g>
<defs>
<filter id="filter0_d_1031_10346" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1031_10346"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1031_10346" result="shape"/>
</filter>
</defs>
</svg>
`
